import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Card, Modal } from '../../components/index'
import {
  StyledCardResultContainer,
  StyledFooterContainer,
  StyledButtonsFooter,
  StyledModalResult,
  StyledHeader,
  StyledCloseImg,
  StyledFooterConference,
} from './style'
import {
  WinnerResult,
  LoserResult,
  BlueCheck,
  ArrowLeftIcon,
  AddCircleIcon,
  CloseButton,
  LoadIcon,
} from '../../assets/images'
import {
  generateCard,
  hashNumbers,
  shuffleNumbers,
  checkCard,
} from '../../utils'
import { BingoDrawerContext } from '../../context/BingoDrawerContext'
import { AppContext } from '../../context/AppContext'

const CardResult = ({ token, handleCloseButton, activeRules }) => {
  const shuffled = shuffleNumbers(hashNumbers, token)
  const cardNumbers = generateCard(shuffled)
  const navigate = useNavigate()

  const [nextStep, setNextStep] = useState(0)
  const [newConference, setNewConference] = useState(false)

  const { orderedDrawnNumbers, drawnNumbers } = useContext(BingoDrawerContext)
  const { setModal } = useContext(AppContext)

  const handleStage = async () => {
    const step = await checkCard({
      cardNumbers,
      orderedDrawnNumbers,
      drawnNumbers,
      activeRules,
    })
    setNextStep(step)
  }

  useEffect(() => {
    handleStage()
  }, [])

  const navigateFromHome = () => {
    handleCloseButton()
    navigate('/home')
  }

  const handleNextStep = () => {
    setModal(false)
    setNextStep(0)
    setNewConference(true)
  }

  const handleConference = () => {
    handleStage()
  }

  const calcInnerWidth = () => {
    return window.innerWidth <= 540
      ? `-${window.innerWidth - 320}px`
      : `-${window.innerWidth - 750}px`
  }

  return (
    <>
      <StyledHeader>
        <p>Conferir cartela</p>
        <StyledCloseImg
          src={CloseButton}
          alt="close-button"
          onClick={handleCloseButton}
          data-cy="close-btn"
        />
      </StyledHeader>
      <StyledCardResultContainer>
        <Card cardNumbers={cardNumbers} token={token} />
        {nextStep === 1 && (
          <StyledModalResult
            className="result-winner"
            innerWidth={calcInnerWidth}
          >
            <Modal
              imgBlocText={WinnerResult}
              title="Parabéns, você bingou! :)"
              subtitle="Agora é hora de aproveitar o prêmio!"
              iconButtonSecondary={ArrowLeftIcon}
              labelButtonSecondary="Voltar para sorteador"
              handleButtonSecondary={handleCloseButton}
              iconButtonPrimary={AddCircleIcon}
              labelButtonPrimary="Nova rodada"
              handleButtonPrimary={navigateFromHome}
            />
          </StyledModalResult>
        )}
        {nextStep === 2 && (
          <StyledModalResult
            className="result-loser"
            innerWidth={calcInnerWidth}
          >
            <Modal
              imgBlocText={LoserResult}
              title="Não foi dessa vez!"
              subtitle="Que pena! Você ainda não bingou!"
              iconButtonSecondary={ArrowLeftIcon}
              labelButtonSecondary="Voltar para cartela"
              handleButtonSecondary={handleNextStep}
              iconButtonPrimary={BlueCheck}
              labelButtonPrimary="Segue o jogo"
              handleButtonPrimary={handleCloseButton}
            />
          </StyledModalResult>
        )}
      </StyledCardResultContainer>
      <StyledFooterContainer data-cy="conference-footer">
        {newConference ? (
          <StyledButtonsFooter>
            <Button
              className="button-secondary"
              label="Voltar ao sorteador"
              onClick={handleCloseButton}
              clear
            />
            <Button
              className="button-primary"
              label="Conferir cartela"
              icon={BlueCheck}
              onClick={handleConference}
            />
          </StyledButtonsFooter>
        ) : (
          <StyledFooterConference>
            <img className="load-icon" src={LoadIcon} alt="loading" />
            <span className="footer-text">Conferindo cartela...</span>
          </StyledFooterConference>
        )}
      </StyledFooterContainer>
    </>
  )
}

export default CardResult
