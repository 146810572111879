import styled from 'styled-components'
import theme from '../../assets/styles/theme/default'

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  position: fixed;
  width: 750px;
  height: 100%;
  top: 0px;
  right: 0px;
  background-color: ${theme.colors.white};
  box-shadow: 0px 3px 8px ${theme.rgba.black024};

  .search-input {
    display: flex;
    align-items: center;
    width: 690px;

    .input-wrapper {
      min-height: 0px;
      gap: 8px;

      .input-tipTexImg {
        margin: 0;
        gap: 12px;

        img {
          width: 24px;
          height: 24px;
          margin: 0px;
        }

        span {
          align-self: center;
          margin: 0px;
        }
      }
    }

    .invalid-message {
      margin: 0px;
      font-size: 14px;
    }

    span {
      font-size: 16px;
      font-weight: 700;
      line-height: 19.2px;
      color: ${theme.colors.eastBay};
    }

    input {
      width: 655px;
      padding: 10px 14px;
    }
  }

  @media (max-width: 540px) {
    width: 320px;

    .search-input {
      width: 300px;

      .input-wrapper {
        gap: 5px;

        .input-tipTexImg {
          gap: 8px;

          img {
            width: 18px;
            height: 18px;
          }
        }
      }

      .invalid-message {
        font-size: 10px;
      }

      span {
        font-size: 12px;
      }

      input {
        width: 200px;
        padding: 5px 10px;
        font-size: 12px;
      }
    }
  }
`

export const StyledCloseImg = styled.img`
  position: absolute;
  right: 12px;
  top: 16px;
  width: 32px;
  height: 32px;
  z-index: 1;
  cursor: pointer;

  @media (max-width: 540px) {
    width: 18px;
    height: 18px;
    top: 17px;
  }
`

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  width: 710px;
  height: 24px;
  top: 0px;
  padding: 20px;
  background-color: ${theme.colors.turquoise};
  border-bottom: 0.5px solid ${theme.colors.bluishGray};

  p {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: ${theme.colors.white};
  }

  @media (max-width: 540px) {
    width: 292px;
    padding: 14px;

    p {
      font-size: 18px;
    }
  }
`

export const StyledBody = styled.div`
  width: 692px;
  height: 90%;
  margin-top: 64px;
  padding: 24px 30px;
  overflow-y: auto;
  scrollbar-color: ${theme.colors.babyBlue} ${theme.colors.whiteSmoke};

  .scroll-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }

  p {
    font-size: 16px;
    font-weight: 700;
    line-height: 19.2px;
    letter-spacing: 0.16px;
    color: ${theme.colors.eastBay};
  }

  @media (max-width: 540px) {
    width: 300px;
    margin-top: 53px;
    padding: 10px;

    .scroll-container {
      width: 300px;
      gap: 18px;
    }

    p {
      font-size: 12px;
    }
  }
`

export const StyledRulesContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 761px;
  gap: 26px;

  @media (max-width: 540px) {
    height: 380px;
    gap: 14px;
  }
`

export const StyledRuleTipText = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 12px;

  img {
    width: 24px;
    height: 24px;
  }

  @media (max-width: 540px) {
    width: 300px;
    gap: 8px;

    img {
      width: 18px;
      height: 18px;
    }

    p {
      line-height: 14px;
    }
  }
`

export const StyledFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 713px;
  height: 127px;
  padding-right: 37px;
  gap: 10px;
  border-top: 1px solid ${theme.rgba.linkWater093};

  button > span {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }

  > button:first-child {
    width: 184px;
    height: 44px;
    padding: 10px 16px;
  }

  > button:last-child {
    width: 182px;
    height: 44px;
    padding: 10px 16px;
    gap: 10px;
    flex-direction: row-reverse;

    img {
      width: 18px;
      height: 18px;
    }
  }

  @media (max-width: 540px) {
    flex-direction: column;
    justify-content: space-evenly;
    width: 320px;
    padding: 0px;
    gap: 0px;

    button:first-child {
      width: 50%;
      padding: 10px;

      span {
        font-size: 14px;
        line-height: 20px;
      }
    }

    button:last-child {
      width: 50%;
      padding: 10px;
      gap: 8px;

      span {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
`
