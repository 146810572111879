import styled, { css } from 'styled-components'
import theme from '../../assets/styles/theme/default'

export const StyledSkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;

  @media (max-width: 540px) {
    justify-content: center;
  }

  ${(props) =>
    props.path === 'home' &&
    css`
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      text-align: center;

      @media (max-width: 540px) {
        align-items: center;
        position: relative;
        width: 100%;
      }
    `}
`

export const StyledHeadSkeleton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  background-color: ${theme.colors.white};
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);

  .react-loading-skeleton.left-logo {
    width: 33px;
    height: 32px;
    margin-left: 40px;
  }

  .react-loading-skeleton.right-name {
    width: 256px;
    height: 24px;
    margin-right: 40px;
  }

  @media (max-width: 540px) {
    .react-loading-skeleton.left-logo {
      display: none;
    }

    .react-loading-skeleton.right-name {
      margin-right: 20px;
    }
  }
`

export const StyledHomeSkeleton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 538px;
  height: 80%;
  margin: 66.5px;

  .react-loading-skeleton.img-rebase {
    width: 154px;
    height: 142px;
    margin-top: 100px;
    margin-bottom: 36px;
  }

  .react-loading-skeleton.text {
    width: 283px;
    height: 32px;
    margin-bottom: 36px;
  }

  .buttons {
    display: flex;
    justify-content: center;
    gap: 0px 30px;
  }

  .react-loading-skeleton.button {
    width: 250px;
    height: 232px;
  }

  @media (max-width: 540px) {
    position: unset;
    width: auto;
    height: 100%;
    margin: 32px;

    .react-loading-skeleton.img-rebase {
      width: 115px;
      height: 101px;
      margin-top: 50px;
    }

    .react-loading-skeleton.text {
      width: 232px;
      margin-bottom: 10px;
    }

    .buttons {
      gap: 0px 20px;
      margin: 0px 10px;
    }

    .react-loading-skeleton.button {
      width: 130px;
      height: 146px;
    }
  }
`

export const StyledCardSkeleton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
  background-color: ${theme.colors.solitude};

  .grid {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 531px;
    height: 570px;
    gap: 15px;
    border-radius: 12px;
    background-color: ${theme.colors.veryLightGrey};
  }

  .col {
    width: 78px;
    height: 530px;

    span {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .react-loading-skeleton {
        height: 75px;
        background-color: ${theme.colors.white};
      }
    }
  }

  @media (max-width: 540px) {
    .grid {
      width: 300px;
      height: 360px;
    }

    .col {
      width: 40px;
      height: 320px;

      span {
        .react-loading-skeleton {
          height: 40px;
        }
      }
    }
  }
`

export const StyledDrawSkeleton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 90vh;
  background-color: ${theme.colors.solitude};

  .grid {
    display: flex;
    align-items: center;
    gap: 30px;

    .col {
      display: flex;
      height: 70vh;

      span {
        display: flex;
        flex-direction: column;

        .react-loading-skeleton {
          width: 175px;
          height: 40px;
        }

        .react-loading-skeleton:first-child {
          height: 50px;
        }
      }
    }
  }

  .btn-draw {
    width: 400px;
    height: 65px;
    border-radius: 10px;
  }

  @media (max-width: 540px) {
    flex-direction: column;
    justify-content: center;
    gap: 10px;

    .grid {
      height: 64vh;
      gap: 5px;

      .col {
        height: 60vh;

        span {
          .react-loading-skeleton {
            width: 30px;
            height: 20px;
          }

          .react-loading-skeleton:first-child {
            height: 20px;
          }
        }
      }
    }

    .btn-draw {
      width: 200px;
      height: 35px;
    }
  }
`

export const StyledFooterSkeleton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;

  .react-loading-skeleton.left-logo {
    width: 73px;
    height: 20px;
    margin-left: 40px;
  }

  .react-loading-skeleton.right-logo {
    width: 228px;
    height: 20px;
    margin-right: 40px;
  }

  @media (max-width: 540px) {
    justify-content: center;

    .react-loading-skeleton.left-logo {
      display: none;
    }

    .react-loading-skeleton.right-logo {
      position: relative;
      margin-right: 0px;
    }
  }

  ${(props) =>
    props.path === 'home' &&
    css`
      position: relative;
      width: 671px;
      margin-top: 43px;
      background-color: ${theme.colors.solitude};

      .react-loading-skeleton.left-logo {
        width: 207px;
        height: 16px;
      }

      .react-loading-skeleton.right-logo {
        width: 191px;
        height: 16px;
      }

      @media (max-width: 540px) {
        display: flex;
        align-items: center;
        justify-content: center;
        left: 0px;
        width: 100%;
        margin-top: 30px;

        .react-loading-skeleton.left-logo {
          display: none;
        }

        .react-loading-skeleton.right-logo {
          position: relative;
        }
      }
    `}
`
