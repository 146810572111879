import styled, { keyframes } from 'styled-components'
import theme from '../../assets/styles/theme/default'

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  width: 710px;
  height: 24px;
  top: 0px;
  padding: 20px;
  background-color: ${theme.colors.turquoise};
  border-bottom: 0.5px solid ${theme.colors.bluishGray};

  p {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: ${theme.colors.white};
  }

  @media (max-width: 540px) {
    width: 292px;
    padding: 14px;

    p {
      font-size: 18px;
    }
  }
`

export const StyledCloseImg = styled.img`
  position: absolute;
  right: 12px;
  top: 16px;
  width: 32px;
  height: 32px;
  z-index: 1;
  cursor: pointer;

  @media (max-width: 540px) {
    width: 18px;
    height: 18px;
    top: 17px;
  }
`

export const StyledCardResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 690px;
  height: 90%;
  margin-top: 65px;
  padding: 24px 30px;

  .card-base {
    display: flex;
    justify-content: center;
    width: 354px;
    height: 391px;
    padding: 23px;

    .grid-card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0px;
      gap: 10.5px;

      > div {
        justify-content: center;
        align-items: center;
        width: 354px;
        margin: 0px;
        gap: 15px;

        .card-header,
        .card-cell,
        .card-img {
          width: 56px;
          height: 56px;
          margin: 0px;
          border-radius: 3px;
        }

        .card-img img {
          width: 36.47px;
          height: 38.335px;
        }

        .card-header {
          p {
            font-size: 36px;
          }
        }

        .card-cell {
          p {
            font-size: 32px;
          }
        }
      }
    }
  }

  @media (max-width: 540px) {
    width: 90%;
    height: 83%;
    margin: 0px;
    padding: 0px;

    .card-base {
      width: 250px;
      height: 300px;
      margin: 0px;
      padding: 15px;

      .grid-card {
        > div {
          width: 95%;
          gap: 10px;

          .card-header,
          .card-cell,
          .card-img {
            width: 40px;
            height: 40px;
          }

          .card-img img {
            width: 25px;
            height: 25px;
          }

          .card-header {
            p {
              font-size: 28px;
            }
          }

          .card-cell {
            p {
              font-size: 24px;
            }
          }
        }
      }
    }
  }
`

export const StyledModalResult = styled.div`
  .modal {
    left: ${(props) => props.innerWidth};

    .container {
      .div-buttons {
        .button-secondary {
          padding: 10px 8px;
        }
      }
    }
  }

  @media (max-width: 540px) {
    .modal {
      .container {
        width: 325px;
        gap: 18px;

        .div-warning {
          img {
            width: 55%;
          }

          .div-text {
            div {
              line-height: 18px;
            }
          }
        }

        .div-buttons {
          padding: 16px;
          gap: 10px;

          .button-secondary {
            padding: 8px;

            span {
              line-height: 18px;
            }
          }
        }
      }
    }
  }
`

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

export const StyledFooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 750px;
  height: 82px;
  padding: 20px 0px;
  gap: 18px;
  border-top: 1px solid ${theme.rgba.linkWater093};

  .footer-text {
    display: flex;
    justify-content: center;
    color: ${theme.colors.turquoise};
    font-size: 24px;
    font-weight: 700;
    line-height: 28.8px;
    letter-spacing: 0.24px;
  }

  .load-icon {
    width: 28px;
    height: 28px;
    animation: ${rotate} 2.5s linear infinite;
  }

  @media (max-width: 540px) {
    width: 320px;
    height: 112px;
    padding: 0px;
    gap: 10px;

    div:first-child {
      margin: 0px;
    }

    .footer-text {
      font-size: 18px;
      line-height: 20px;
    }

    .load-icon {
      width: 20px;
      height: 20px;
    }
  }
`

export const StyledFooterConference = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 18px;

  @media (max-width: 540px) {
    gap: 10px;
  }
`

export const StyledButtonsFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 713px;
  padding-right: 37px;
  gap: 24px;

  span {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }

  img {
    width: 18px;
    height: 18px;
  }

  .button-secondary {
    width: 188px;
    height: 44px;
    padding: 10px 16px;
  }

  .button-primary {
    flex-direction: row-reverse;
    width: 187px;
    height: 44px;
    padding: 10px 16px;
    gap: 10px;

    span {
      color: ${theme.colors.white};
    }
  }

  @media (max-width: 540px) {
    flex-direction: column;
    justify-content: space-evenly;
    width: 320px;
    padding: 0px;
    gap: 8px;

    .button-secondary {
      width: 50%;
      padding: 10px;

      span {
        font-size: 14px;
        line-height: 20px;
      }
    }

    .button-primary {
      width: 50%;
      padding: 10px;
      gap: 8px;

      span {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
`
