import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import {
  StyledSkeletonContainer,
  StyledHeadSkeleton,
  StyledHomeSkeleton,
  StyledCardSkeleton,
  StyledDrawSkeleton,
  StyledFooterSkeleton,
} from './style'

const SkeletonContainer = () => {
  const path = window.location.href.split('/')[4]

  const headSkeleton = () => (
    <StyledHeadSkeleton data-cy="skeleton-head">
      <Skeleton className="left-logo" />
      <Skeleton className="right-name" />
    </StyledHeadSkeleton>
  )

  const homeSkeleton = () => (
    <StyledHomeSkeleton>
      <Skeleton className="img-rebase" />
      <Skeleton className="text" />
      <div className="buttons">
        <Skeleton className="button" />
        <Skeleton className="button" />
      </div>
    </StyledHomeSkeleton>
  )

  const gridCards = (col, row) => (
    <div className="grid">
      {Array(col)
        .fill(0)
        .map((_, key) => (
          <div key={key} className="col">
            <Skeleton count={row} />
          </div>
        ))}
    </div>
  )

  const cardSkeleton = () => (
    <>
      {headSkeleton()}
      <StyledCardSkeleton>{gridCards(5, 6)}</StyledCardSkeleton>
    </>
  )

  const drawSkeleton = () => (
    <>
      {headSkeleton()}
      <StyledDrawSkeleton>
        {gridCards(5, 16)}
        <Skeleton className="btn-draw" />
      </StyledDrawSkeleton>
    </>
  )

  return (
    <StyledSkeletonContainer path={path} data-cy="skeleton-container">
      {path === 'home' && homeSkeleton()}
      {path === 'cartela' && cardSkeleton()}
      {path === 'sorteador' && drawSkeleton()}

      <StyledFooterSkeleton path={path} data-cy="skeleton-footer">
        <Skeleton className="left-logo" />
        <Skeleton className="right-logo" />
      </StyledFooterSkeleton>
    </StyledSkeletonContainer>
  )
}

export default SkeletonContainer
