import theme from '../../assets/styles/theme/default'

export const paintDrawnNumbers = (card, drawnNumbers) => {
  const drawnFlat = Object.values(drawnNumbers).flat()

  const validNumbers = drawnFlat.filter((num) =>
    Object.keys(card).some((key) => card[key].includes(num))
  )

  let delay = 500

  return new Promise((resolve) => {
    const promises = validNumbers.map((num, index) => {
      const key = Object.keys(card).find((key) => card[key].includes(num))

      const cell = Array.from(
        document.querySelectorAll(`.card-cell.${key}`)
      ).find((el) => el.textContent == num)

      return new Promise((cellResolve) => {
        cell.style.backgroundColor = theme.colors.white

        setTimeout(
          () => {
            if (cell) {
              cell.style.backgroundColor = theme.colors.harlequin
            }
            cellResolve()
          },
          delay * (index + 1)
        )
      })
    })

    Promise.all(promises).then(() => {
      setTimeout(resolve, 500)
    })
  })
}

const areAllNumbersDrawn = (numbers, drawnNumbers) => {
  const drawnSet = new Set(drawnNumbers)

  return numbers.every((number) => drawnSet.has(number))
}

export const checkFullBingo = (card, drawnNumbers) => {
  const allCardNumbers = Object.values(card).flat()

  return areAllNumbersDrawn(allCardNumbers, drawnNumbers)
}

export const checkTipsBingo = (card, drawnNumbers) => {
  const tipsCardNumbers = [
    card['B'][0],
    card['B'][4],
    card['O'][0],
    card['O'][4],
  ]

  return areAllNumbersDrawn(tipsCardNumbers, drawnNumbers)
}

export const checkDiagonalBingo = (card, drawnNumbers) => {
  const primaryDiagonalNumbers = [
    card['B'][0],
    card['I'][1],
    card['G'][3],
    card['O'][4],
  ]
  const secondaryDiagonalNumbers = [
    card['O'][0],
    card['G'][1],
    card['I'][3],
    card['B'][4],
  ]

  return (
    areAllNumbersDrawn(primaryDiagonalNumbers, drawnNumbers) ||
    areAllNumbersDrawn(secondaryDiagonalNumbers, drawnNumbers)
  )
}

export const checkHorizontalBingo = (card, drawnNumbers) => {
  for (let i = 0; i < 5; i++) {
    const rowNumbers = [
      card['B'][i],
      card['I'][i],
      i == 2 ? null : card['N'][i > 2 ? i - 1 : i],
      card['G'][i],
      card['O'][i],
    ]
    const numbersToCheck = rowNumbers.filter((number) => number != null)
    if (areAllNumbersDrawn(numbersToCheck, drawnNumbers)) return true
  }

  return false
}

export const checkVerticalBingo = (card, drawnNumbers) => {
  return Object.values(card).some((columnNumbers) =>
    areAllNumbersDrawn(columnNumbers, drawnNumbers)
  )
}
